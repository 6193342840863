import React from "react";

import PageHead from "@Components/PageHead";
import { graphql, useStaticQuery } from "gatsby";
import CallToAction from "@Components/CallToAction";

function ReactNative() {
  const data = useStaticQuery(PageQuery);
  return (
    <>
      <PageHead backgroundImg={data.mobileHero.childImageSharp.fluid}>
        <h2 className="head-title">
          Cross Platform <span>Mobile</span> Apps
        </h2>
      </PageHead>
      <CallToAction />
    </>
  );
}

export default ReactNative;

export const PageQuery = graphql`
  query {
    mobileHero: file(relativePath: { eq: "mobile_hero_optimized.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
